import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="character-classes"></a>Character Classes</h2>
    <h3><a id="ranger"></a>RANGER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/ph35_gallery/PHB35_PG46_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Alignment</b>:
Any.</p>
    <p><b>Hit Die</b>: d8.</p>
    <h6>Class Skills</h6>
    <p className="initial">The ranger&#8217;s class skills (and the key ability
for each skill) are <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a> (Str), <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a>
(Con), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#handle-animal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Handle Animal</a> (Cha), <a href="skillsAll.html#heal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Heal</a>
(Wis), <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
(Dex), <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
(Str), <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a>
(dungeoneering/geography/nature) (Int), <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
(Wis), <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move Silently</a> (Dex), <a href="skillsAll.html#profession" style={{
        "color": "rgb(87, 158, 182)"
      }}>Profession</a>
(Wis), <a href="skillsAll.html#ride" style={{
        "color": "rgb(87, 158, 182)"
      }}>Ride</a>
(Dex), <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a> (Int), <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
(Wis), <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a> (Wis), <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a>
(Str), and <a href="skillsAll.html#use-rope" style={{
        "color": "rgb(87, 158, 182)"
      }}>Use Rope</a> (Dex).</p>
    <p><b>Skill Points at 1st Level</b>: (6 + Int modifier) x 4.</p>
    <p><b>Skill Points at Each Additional Level</b>: 6 + Int modifier.</p>
    <p><a id="table-the-ranger"></a><b>Table: The Ranger</b></p>
    <table summary="table: the ranger" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Level</th>
          <th rowSpan="2" style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Fort
Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Ref
Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Will
Save</th>
          <th rowSpan="2" style={{
            "width": "40%"
          }}>Special</th>
          <th colSpan="4" style={{
            "textAlign": "center"
          }}>Spells per Day</th>
        </tr>
        <tr>
          <th style={{
            "width": "6%"
          }}>1st</th>
          <th style={{
            "width": "6%"
          }}>2nd</th>
          <th style={{
            "width": "6%"
          }}>3rd</th>
          <th style={{
            "width": "6%"
          }}>4th</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+1</td>
          <td>+2</td>
          <td>+2</td>
          <td>+0</td>
          <td>1st favored enemy, Track, wild empathy</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+2</td>
          <td>+3</td>
          <td>+3</td>
          <td>+0</td>
          <td>Combat style</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+3</td>
          <td>+3</td>
          <td>+3</td>
          <td>+1</td>
          <td>Endurance</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+4</td>
          <td>+4</td>
          <td>+4</td>
          <td>+1</td>
          <td>Animal companion</td>
          <td>0</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+5</td>
          <td>+4</td>
          <td>+4</td>
          <td>+1</td>
          <td>2nd favored enemy</td>
          <td>0</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+6/+1</td>
          <td>+5</td>
          <td>+5</td>
          <td>+2</td>
          <td>Improved combat style</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+7/+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>+2</td>
          <td>Woodland stride</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+8/+3</td>
          <td>+6</td>
          <td>+6</td>
          <td>+2</td>
          <td>Swift tracker</td>
          <td>1</td>
          <td>0</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+9/+4</td>
          <td>+6</td>
          <td>+6</td>
          <td>+3</td>
          <td>Evasion</td>
          <td>1</td>
          <td>0</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+10/+5</td>
          <td>+7</td>
          <td>+7</td>
          <td>+3</td>
          <td>3rd favored enemy</td>
          <td>1</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+11/+6/+1</td>
          <td>+7</td>
          <td>+7</td>
          <td>+3</td>
          <td>Combat style mastery</td>
          <td>1</td>
          <td>1</td>
          <td>0</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+12/+7/+2</td>
          <td>+8</td>
          <td>+8</td>
          <td>+4</td>
          <td>&nbsp;</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+13/+8/+3</td>
          <td>+8</td>
          <td>+8</td>
          <td>+4</td>
          <td>Camouflage</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+14/+9/+4</td>
          <td>+9</td>
          <td>+9</td>
          <td>+4</td>
          <td>&nbsp;</td>
          <td>2</td>
          <td>1</td>
          <td>1</td>
          <td>0</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+15/+10/+5</td>
          <td>+9</td>
          <td>+9</td>
          <td>+5</td>
          <td>4th favored enemy</td>
          <td>2</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+16/+11/+6/+1</td>
          <td>+10</td>
          <td>+10</td>
          <td>+5</td>
          <td>&nbsp;</td>
          <td>2</td>
          <td>2</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+17/+12/+7/+2</td>
          <td>+10</td>
          <td>+10</td>
          <td>+5</td>
          <td>Hide in plain sight</td>
          <td>2</td>
          <td>2</td>
          <td>2</td>
          <td>1</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+18/+13/+8/+3</td>
          <td>+11</td>
          <td>+11</td>
          <td>+6</td>
          <td>&nbsp;</td>
          <td>3</td>
          <td>2</td>
          <td>2</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+19/+14/+9/+4</td>
          <td>+11</td>
          <td>+11</td>
          <td>+6</td>
          <td>&nbsp;</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+20/+15/+10/+5</td>
          <td className="last-row">+12</td>
          <td className="last-row">+12</td>
          <td className="last-row">+6</td>
          <td className="last-row">5th favored enemy</td>
          <td className="last-row">3</td>
          <td className="last-row">3</td>
          <td className="last-row">3</td>
          <td className="last-row">3</td>
        </tr>
      </tbody>
    </table>
    <h6><a id="ranger-class-features"></a>Class Features</h6>
    <p className="initial">All of the following are class features of the
ranger.</p>
    <p><b>Weapon and Armor Proficiency</b>: A ranger is proficient
with all simple and martial weapons, and with light armor and shields
(except tower shields).</p>
    <p><a id="ranger-favored-enemy"></a><b>Favored Enemy (Ex)</b>: At 1st level, a ranger may select a
type of creature from among those given on Table: Ranger Favored
Enemies. The ranger gains a +2 bonus on <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>,
      <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense Motive</a>, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>,
and <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a>
checks when using these skills against creatures of
this type. Likewise, he gets a +2 bonus on weapon damage rolls against
such creatures.</p>
    <p>At 5th level and every five levels thereafter (10th, 15th, and
20th level), the ranger may select an additional favored enemy from
those given on the table. In addition, at each such interval, the bonus
against any one favored enemy (including the one just selected, if so
desired) increases by 2.</p>
    <p>If the ranger chooses humanoids or outsiders as a favored
enemy, he must also choose an associated subtype, as indicated on the
table. If a specific creature falls into more than one category of
favored enemy, the ranger&#8217;s bonuses do not stack; he simply uses
whichever bonus is higher.</p>
    <p><a id="table-ranger-favored-enemies"></a><b>Table: Ranger Favored Enemies</b></p>
    <table summary="Table: Ranger Favored Enemies" border="0" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "150px"
          }}>Type (Subtype)</th>
          <th style={{
            "width": "150px"
          }}>Type (Subtype)</th>
          <th style={{
            "width": "150px"
          }}>Type (Subtype)</th>
          <th style={{
            "width": "150px"
          }}>Type (Subtype)</th>
        </tr>
        <tr className="odd-row">
          <td>Aberration</td>
          <td>Humanoid (dwarf)</td>
          <td>Humanoid (reptilian)</td>
          <td>Outsider (fire)</td>
        </tr>
        <tr>
          <td>Animal</td>
          <td>Humanoid (elf)</td>
          <td>Magical beast</td>
          <td>Outsider (good)</td>
        </tr>
        <tr className="odd-row">
          <td>Construct</td>
          <td>Humanoid (goblinoid)</td>
          <td>Monstrous humanoid</td>
          <td>Outsider (lawful)</td>
        </tr>
        <tr>
          <td>Dragon</td>
          <td>Humanoid (gnoll)</td>
          <td>Ooze</td>
          <td>Outsider (native)</td>
        </tr>
        <tr className="odd-row">
          <td>Elemental</td>
          <td>Humanoid (gnome)</td>
          <td>Outsider (air)</td>
          <td>Outsider (water)</td>
        </tr>
        <tr>
          <td>Fey</td>
          <td>Humanoid (halfling)</td>
          <td>Outsider (chaotic)</td>
          <td>Plant</td>
        </tr>
        <tr className="odd-row">
          <td>Giant</td>
          <td>Humanoid (human)</td>
          <td>Outsider (earth)</td>
          <td>Undead</td>
        </tr>
        <tr>
          <td className="last-row">Humanoid (aquatic)</td>
          <td className="last-row">Humanoid (orc)</td>
          <td className="last-row">Outsider (evil)</td>
          <td className="last-row">Vermin</td>
        </tr>
      </tbody>
    </table>
    <a id="ranger-track"></a><p><b>Track</b>: A ranger gains <a href="featsAll.html#track" style={{
        "color": "rgb(87, 158, 182)"
      }}>Track</a> as a bonus feat.</p>
    <a id="ranger-wild-empathy"></a><p><b>Wild Empathy (Ex)</b>: A ranger can improve the attitude of
an animal. This ability functions just like a <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a>
check to
improve the attitude of a person. The ranger rolls 1d20 and adds his
ranger level and his Charisma bonus to determine the wild empathy check
result. The typical domestic animal has a starting attitude of
indifferent, while wild animals are usually unfriendly.</p>
    <p>To use wild empathy, the ranger and the animal must be able to
study each other, which means that they must be within 30 feet of one
another under normal visibility conditions. Generally, influencing an
animal in this way takes 1 minute, but, as with influencing people, it
might take more or less time.</p>
    <p>The ranger can also use this ability to influence a magical
beast with an Intelligence score of 1 or 2, but he takes a &#8211;4 penalty
on the check.</p>
    <p><a id="ranger-combat-style"></a><b>Combat Style (Ex)</b>: At 2nd level, a ranger must select
one of two combat styles to pursue: archery or two-weapon combat. This
choice affects the character&#8217;s class features but does not restrict his
selection of feats or special abilities in any way.</p>
    <p>If the ranger selects archery, he is treated as having the <a href="featsAll.html#rapid-shot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Rapid
Shot</a> feat, even if he does
not have the normal prerequisites for
that feat.</p>
    <p>If the ranger selects two-weapon combat, he is treated as
having the <a href="featsAll.html#two-weapon-fighting" style={{
        "color": "rgb(87, 158, 182)"
      }}>Two-Weapon Fighting</a> feat, even
if he does not have the
normal prerequisites for that feat.</p>
    <p>The benefits of the ranger&#8217;s chosen style apply only when he
wears light or no armor. He loses all benefits of his combat style when
wearing medium or heavy armor.</p>
    <p><a id="ranger-endurance"></a><b>Endurance</b>: A ranger gains <a href="featsAll.html#endurance" style={{
        "color": "rgb(87, 158, 182)"
      }}>Endurance</a>
as a bonus feat at
3rd level.</p>
    <p><a id="ranger-animal-companion"></a><b>Animal Companion (Ex)</b>: At 4th level, a ranger gains an
animal companion selected from the following list: badger, camel, dire
rat, dog, riding dog, eagle, hawk, horse (light or heavy), owl, pony,
snake (Small or Medium viper), or wolf. If the campaign takes place
wholly or partly in an aquatic environment, the following creatures may
be added to the ranger&#8217;s list of options: crocodile, porpoise, Medium
shark, and squid. This animal is a loyal companion that accompanies the
ranger on his adventures as appropriate for its kind.</p>
    <p>This ability functions like the druid ability of the same
name, except that the ranger&#8217;s effective druid level is one-half his
ranger level. A ranger may select from the alternative lists of animal
companions just as a druid can, though again his effective druid level
is half his ranger level. Like a druid, a ranger cannot select an
alternative animal if the choice would reduce his effective druid level
below 1st.</p>
    <p><b>Spells</b>: Beginning at 4th level, a ranger gains the
ability to cast a small number of divine spells, which are drawn from
the <a style={{
        "color": "#579eb6"
      }} href="rangerSpells.html">ranger spell list</a>. A ranger must choose and prepare his spells in
advance (see below).</p>
    <p>To prepare or cast a spell, a ranger must have a Wisdom score
equal to at least 10 + the spell level. The Difficulty Class for a
saving throw against a ranger&#8217;s spell is 10 + the spell level + the
ranger&#8217;s Wisdom modifier.</p>
    <p>Like other spellcasters, a ranger can cast only a certain
number of spells of each spell level per day. His base daily spell
allotment is given on Table: The Ranger. In addition, he receives bonus
spells per day if he has a high Wisdom score. When Table: The Ranger
indicates that the ranger gets 0 spells per day of a given spell level,
he gains only the bonus spells he would be entitled to based on his
Wisdom score for that spell level. The ranger does not have access to
any domain spells or granted powers, as a cleric does.</p>
    <p>A ranger prepares and casts spells the way a cleric does,
though he cannot lose a prepared spell to cast a cure spell in its
place. A ranger may prepare and cast any spell on the ranger spell
list, provided that he can cast spells of that level, but he must
choose which spells to prepare during his daily meditation.</p>
    <p>Through 3rd level, a ranger has no caster level. At 4th level
and higher, his caster level is one-half his ranger level.</p>
    <p><a id="ranger-improved-combat-style"></a><b>Improved Combat Style (Ex)</b>: At 6th level, a ranger&#8217;s
aptitude in his chosen combat style (archery or two-weapon combat)
improves. If he selected archery at 2nd level, he is treated as having
the <a href="featsAll.html#manyshot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Manyshot</a>
feat, even if he does not have the normal prerequisites
for that feat.</p>
    <p>If the ranger selected two-weapon combat at 2nd level, he is
treated as having the <a href="featsAll.html#improved-two-weapon-fighting" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved Two-Weapon Fighting</a>
feat, even if he
does not have the normal prerequisites for that feat.</p>
    <p>As before, the benefits of the ranger&#8217;s chosen style apply
only when he wears light or no armor. He loses all benefits of his
combat style when wearing medium or heavy armor.</p>
    <p><a id="ranger-woodland-stride"></a><b>Woodland Stride (Ex)</b>: Starting at 7th level, a ranger
may move through any sort of undergrowth (such as natural thorns,
briars, overgrown areas, and similar terrain) at his normal speed and
without taking damage or suffering any other impairment. However,
thorns, briars, and overgrown areas that are enchanted or magically
manipulated to impede motion still affect him.</p>
    <p><a id="ranger-swift-tracker"></a><b>Swift Tracker (Ex)</b>: Beginning at 8th level, a ranger
can move at his normal speed while following tracks without taking the
normal &#8211;5 penalty. He takes only a &#8211;10 penalty (instead of the normal
&#8211;20) when moving at up to twice normal speed while tracking.</p>
    <p><a id="ranger-evasion"></a><b>Evasion (Ex)</b>: At 9th level, a ranger can avoid even
magical and unusual attacks with great agility. If he makes a
successful Reflex saving throw against an attack that normally deals
half damage on a successful save, he instead takes no damage. Evasion
can be used only if the ranger is wearing light armor or no armor. A <a href="abilitiesAndConditions.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a> ranger does not gain
the benefit of evasion.</p>
    <p><a id="ranger-combat-style-mastery"></a><b>Combat Style Mastery (Ex)</b>: At 11th level, a ranger&#8217;s
aptitude in his chosen combat style (archery or two-weapon combat)
improves again. If he selected archery at 2nd level, he is treated as
having the <a href="featsAll.html#improved-precise-shot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved Precise Shot</a> feat, even
if he does not have the
normal prerequisites for that feat.</p>
    <p>If the ranger selected two-weapon combat at 2nd level, he is
treated as having the <a href="featsAll.html#greater-two-weapon-fighting" style={{
        "color": "rgb(87, 158, 182)"
      }}>Greater Two-Weapon Fighting</a>
feat, even if he does
not have the normal prerequisites for that feat.</p>
    <p>As before, the benefits of the ranger&#8217;s chosen style apply
only when he wears light or no armor. He loses all benefits of his
combat style when wearing medium or heavy armor.</p>
    <p><a id="ranger-camouflage"></a><b>Camouflage (Ex)</b>: A ranger of 13th level or higher can
use the Hide skill in any sort of natural terrain, even if the terrain
doesn&#8217;t grant cover or concealment.</p>
    <p><b>Hide in Plain Sight (Ex)</b>: While in any sort of natural
terrain, a ranger of 17th level or higher can use the Hide skill even
while being observed.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      